@font-face {
    font-family: didact-gothic-regular;
    src: url(../../assets/fonts/DidactGothic-Regular.ttf);
}

@font-face {
    font-family: helveticanue;
    src: url(../../assets/fonts/HelveticaNeueMedium.ttf);
}

@font-face {
    font-family: helveticaRegular;
    src: url(../../assets/fonts/Helvetica-regular.ttf);
  }

.didact-30 {
    font-family: didact-gothic-regular;
    font-size: 30px !important; 
}

.amount-paid {
    font-family: helveticanue !important; 
    font-size: 16px !important; 
}

.helv-nue-24 {
    font-family: helveticanue !important; 
    font-size: 24px !important; 
}

.helv-reg-16 {
    font-family: helveticaRegular !important; 
    font-size: 16px !important; 
}

.helv-reg-22-white {
    font-family: helveticaRegular !important; 
    font-size: 22px !important; 
    color: #FFFFFF;
}

.div-center-85 {
    margin: auto;
    width: 85%;
}

.div-center-90 {
    margin: auto;
    width: 90%;
}

.div-center-95 {
    margin: auto;
    width: 95%;
}

.tipbg {
    background-color: #fff;
}

.tipbg label{
    /* font-family: 'Didact Gothic' !important; */
    /* font-family: 'didact Gothic'; */
    font-style: normal;
    font-size: 24px;
    line-height: 35px;
    color: #1e3343  !important;
  
}

.font-big{
    font-size: 26px;
    font-weight: 500;

    
}
.box-b1 {
    box-sizing: border-box;
    border-radius: 8px;
}
.box-b2 {
    border: 1px solid #D9D9D9;
    width: 240px;
    height: 120px;
    margin:auto;
    border-radius:8px;
    padding: 5px 5px, 5px, 5px ;
}
.innerbox-b1 {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 19px;  
}

.hotel_logo{
    height: 50px;
    margin-right: 20px;
    margin-top: 10px;
}

.amt-div {
    font-size: 16px;
}
.hotel_name{
    width: 100%;
    margin-top: 10px;
    margin-right: 20px;
    font-weight: bold;
}

