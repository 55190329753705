@font-face {
  font-family: tipfont;
  src: url(../../assets/fonts/DINOT.OTF);
}


@font-face {
  font-family: tipbfont;
  src: url(../../assets/fonts/DINOT-BOLD.OTF);
}

@font-face {
  font-family: tiplfont;
  src: url(../../assets/fonts/DINOT-LIGHT.OTF);
}

@font-face {
  font-family: ppfbold;
  src: url(../../assets/fonts/ppf-bold.otf);
}

@font-face {
  font-family: ppflight;
  src: url(../../assets/fonts/ppf-light.otf);
}

@font-face {
  font-family: helvaticab;
  src: url(../../assets/fonts/Helnb.ttf);
}

@font-face {
  font-family: helveticanue;
  src: url(../../assets/fonts/HelveticaNeueMedium.ttf);
}

@font-face {
  font-family: helveticalight;
  src: url(../../assets/fonts/Helvetica-Light.ttf);
}

@font-face {
  font-family: helvetica;
  src: url(../../assets/fonts/Helvetica.ttf);
}

@font-face {
  font-family: helveticaRegular;
  src: url(../../assets/fonts/Helvetica-regular.ttf);
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: tipfont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.input-icons {
  width: 100%;
  max-width: 35em;
  margin-bottom: 10px !important;
  position: relative;
  margin: 0 auto;
}

.icon {
  padding: 14px;
  min-width: 40px;
  position: absolute !important;
  font-size: 22px;
}

.hotel_icon{
  padding: 12px;
  min-width: 40px;
  position: absolute;
  font-size: 17px;
}


.fontz{
  font-family: helveticanue !important;
  font-size: 10px;
  font-weight: 700;
}

.backbtn{
  text-decoration: none;
  font-size: 35px;
  color: black;
  margin-right: 15px;
  margin-top: 10px; 
}

.apay{
  font-size: 20px;
}

.gpayicon{
  width: 18px;
    margin-top: -3px;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

.align-center-bkup {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  min-width: 90vw;
  margin: 50px;
}

.align-center-register {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80vw;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  min-width: 80vw;
  margin: 35px;
}

.MuiSlider-rail {
  background-color: #d6d5d2 !important;
}

.MuiSlider-mark {
  height: 5px !important;
  width: 5px !important;
  left: auto;
  z-index: 1;
  color: #D9D9D9 !important;
  border-radius: 50px;
}

.MuiSlider-track {
  background-color: #0077ff !important;
}

.MuiSlider-root {
  height: 3px !important;
}

.MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium.MuiSlider-thumb::before {
  z-index: 2 !important;
  background-color: #2878FA !important;
  box-shadow: none !important;
}

.MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium.MuiSlider-thumb::after {
  border: 1px solid !important;
  width: 30px !important;
  height: 30px !important;
  top: 50% !important;
  left: 50% !important;
  color: #2878FA !important;
  z-index: 1 !important;
  background-color: #fff !important;
}

.tipbg{
  background-color: #ECEBE7
}

.mainbg{
  background-color: #efebe8;
}

.shaddow{
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 11%);
}

.bold{
  font-weight: bold;
  color: #1e3343  !important;
  font-family: helveticanue;
}

.bold-text{
  font-weight: bold !important;
}

.value-box-bkup{
  margin: 2px 10px 10px 10px;
  height: 45px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgba(0,0,0,.2); */
  box-shadow: 0px 1px 2px 3px rgb(60 64 67 / 8%);
  font-weight: bold;
  border-radius: 5px;
  font-size: 20px;
}

.value-box{
  margin: 2px 0px 10px 0px;
  padding-left: 10px; 
  padding-right: 10px; 
  height: 45px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D9D9D9 ; 
  /* box-shadow: 0px 1px 2px 3px rgb(60 64 67 / 8%); */
  border-radius: 7px;
  font-size: 24px;
}

/* tip crad */
.card-backGround-bkup {
  position:relative;
  background-color: #ebe8e1 !important;
  color: black !important;
  padding: 8px 19px;
  height: 265px;
  box-shadow: 0px 1px 2px 3px rgb(60 64 67 / 8%);
  border-radius: 10px;
  font-family: tipfont !important;
}

.card-backGround {
  position:relative;
  background-color: #F5F5F5 !important;
  color: black !important;
  padding: 8px 19px;
  height: 265px;
  border-radius: 0px;
  font-family: tipfont !important;
}

/* slider label */
.MuiSlider-valueLabelOpen.MuiSlider-valueLabel{
  background-color: transparent !important;
  color: black !important;
}

/* tip header  */
.card-backGround h4{
  font-family: helveticaRegular !important;
  color: #414141  !important;
  font-size: 18px;
}


/* custom tip input */
#search1 {
  position: relative;
  width:100%
}

/* dollarsign in custom tip */
#search1 .fa {
  color: black;
  top: 15px;
  left: 9px;
  position: absolute;
  font-size: 20px;
}

#search-icon1 {
  padding-left: 20px;
}


.card-backGround p{
  font-family: helveticaRegular !important;
  font-size: 16px;
  color: #414141  !important;
  padding-top: 2px;
}

.hero {
  position:relative;
  background-color:#e15915;
  height:320px !important;
  width:100% !important;
}

/* .card-backGround:after {
  content:'';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 50px #e15915;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
} */


.redbrdr,.redbrdr:focus,.redbrdr:hover{
  border-width: 1;
  border-color: red !important;
}

/* triangle tool tip in tip card */
/* .value-box:after {
  content:'';
  position: absolute;
  top: -19px;
  left: -8px !important;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 100px #ebe8e1;
  box-sizing: border-box;
  border: 10px solid black;
  border-color: transparent transparent #ebe8e1 #ebe8e1;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: -3px 3px 2px 0 rgb(0 0 0 / 9%);
} */

.value-box:after {
  content:'';
  position: absolute;
  top: -19px;
  left: -8px !important;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 100px #F5F5F5;
  box-sizing: border-box;
  border: 10px solid black;
  border-color: transparent transparent #F5F5F5 #F5F5F5;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: -3px 3px 2px 0 rgb(0 0 0 / 5%);
}

.tipinpbg{
 /* background-color:   #d9d9d9 !important; */
 background-color:   #fff !important;
 border-radius: 10px !important;
 /* margin: 5px; */
 font-size: 20px;
 height: 50px;
 border: 1px solid #D9D9D9;
 font-family: helveticanue !important;
}

/* custom font */
.helvfont{
  font-family: helveticanue !important;
}

.font-15{
  font-size: 15px;
}

.font-20{
  font-size: 20px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  text-decoration: underline !important;
}

.custom_v{
  cursor: pointer;
  margin: 16px 0 10px -1px;
  font-family: helveticaRegular !important;
  font-size: 16px;
}

.bgblack{
  background-color: black !important;
  color: white;
}

.gpaybtn{
  background-color: #223242;
  color: white;
}

.btnbg-bkup{
background-color: #2878FA !important;
color: white;
}

.btnbg{
  background-color: #1E3343 !important;
  color: white !important;
  border-radius: 8px !important;
}

.btn-text{
  font-family: helveticaRegular !important;
  font-size: 19px !important; 
  font-weight: lighter !important; 
} 

/* input, button {
  max-width: 35rem !important;
} */


/* stripe card element styling */
.StripeElement {
 


  max-width: 500px;
  width: 100%;
  padding: 10px 14px;
  font-size: 1em;
  outline: 0;
  border-radius: 0px;
  background: transparent;
  font-family: helveticanue !important;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}


.StripeElement .InputElement{
  font-family: helveticanue !important;
}

label {
  color: #676767;
  font-weight: 300;
  letter-spacing: 0.025em;
}


.Demo {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-bottom: 40%; */
  max-width: 500px;
  text-align: left;
  margin: 0 auto;
  background-color: white;
  border: 1px solid;
  border-radius: 10px;
  margin-bottom: 10px;
}


.StripeElement:nth-of-type(1){
  /* border-bottom: 1px solid !important; */
}


.topCardnumber  .StripeElement:first-child{
  border-bottom: 1px solid !important;
}


.text-left{
  text-align: left;
}

.m0{
  margin:0
}

.b0{
  border:none
}

.tip-p-left {
  text-align: left !important;;
}

.tip-factor-bg {
  background-color: #F5F5F5 !important;
}

.tip-factor-header {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
}

.tip-factor-text {
  font-size: 16px;
}

.tip-normal-text {
  font-family: helveticanue !important;
  font-size: 16px;
}

.main-block {
  display: flex;
  flex-direction: column;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.sm-icons {
  height: 0.9rem;
}

.css-eg0mwd-MuiSlider-thumb {
  width: 0px !important;
  z-index: 1 !important;
}

.css-1gv0vcd-MuiSlider-track {
  height: 3px !important;
}
.css-14pt78w-MuiSlider-rail {
  height: 3px !important;
}

.css-b04pc9 {
  height: 3px !important;
}

.css-1t2bqnt {
  height: 3px !important;
}

.MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium.MuiSlider-thumb::after {
  color: #ECEBE7 !important;
  box-shadow: 1px 1px #ECEBE7;
  background-color: #ffffff !important;
  z-index: 999 !important;
}

/* .css-1tfve6q-MuiSlider-mark {
  border-radius: 50px !important;
} */

.div-center-60 {
  margin: auto;
  width: 60%;
}

.div-center-65 {
  margin: auto;
  width: 65%;
}
.div-center-72 {
  margin: auto;
  width: 72%;
}
.div-center-75 {
  margin: auto;
  width: 75%;
}

.div-center-85 {
  margin: auto;
  width: 85%;
}

.roomNum-width {
  width: 75% !important;
}

.tip-input::-webkit-input-placeholder {
  font-size: 17px;
  font-family: helveticalight, Arial, Helvetica, sans-serif !important;
  color: #414141;
}

.tip-input:-ms-input-placeholder {
  font-size: 17px;
  font-family: helveticalight, Arial, Helvetica, sans-serif !important;
  color: #414141;
}

.tip-input:-moz-placeholder {
  font-size: 17px;
  font-family: helveticalight, Arial, Helvetica, sans-serif !important;
  color: #414141;
}

.tip-input::-moz-placeholder {
  font-size: 17px;
  font-family: helveticalight, Arial, Helvetica, sans-serif !important;
  color: #414141;
}

.MuiSlider-markActive {
  color: #98BEFD !important;
  background-color: #98BEFD !important;
}

.helv-regular {
  font-family: helveticaRegular !important; 
}

.div-width460 {
  width: 460px;
  display: table-cell;
}
